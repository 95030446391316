import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { File } from '@/../graphql-types';

const awardsQuery = graphql`
  query Awards {
    allAwardsJson {
      nodes {
        id
        logo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [480, 640, 768, 1024, 1280, 1536]
              quality: 90
              placeholder: BLURRED
            )
          }
        }
        title
        description
        links
        certificate {
          publicURL
        }
      }
    }
  }
`;

interface IAward {
  id: string;
  title: string;
  description: string;
  logo: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  certificate?: Pick<File, 'publicURL'>;
  links: string[];
}

function fullLink(link: string): string {
  if (!link.includes('://')) {
    return `https://${link}`;
  }

  return link;
}

export const Awards = (): ReactElement => {
  const { t } = useTranslation();
  const q = useStaticQuery(awardsQuery);
  const awards = q.allAwardsJson.nodes as IAward[];

  return (
    <>
      {awards.map((award) => (
        <section
          key={award.id}
          className="relative w-full py-16 flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center max-w-screen-lg mx-auto"
        >
          <div className="w-full sm:w-1/4 flex-shrink-0 max-w-xs mb-5 sm:mb-0 px-12">
            <GatsbyImage
              image={award.logo.childImageSharp.gatsbyImageData}
              alt={award.title}
              sizes="(min-width: 1536px) 33vw, (min-width: 768px) 50vw, 100vw"
            />
          </div>
          <div className="text-center sm:text-left w-full px-4">
            <h2 className="title-text mb-4 text-3xl xl:text-4xl">
              {award.title}
            </h2>
            {award.description && (
              <div className="text-white sm:text-lg xl:text-xl mb-4">
                ({award.description})
              </div>
            )}
            {award.links.map((link) => (
              <a
                key={link}
                target="_blank"
                href={fullLink(link)}
                className="block font-serif text-gold-default hover:text-gold-brighter text-lg xl:text-xl truncate"
                rel="noreferrer"
              >
                {link}
              </a>
            ))}
            {award.certificate && (
              <a
                className="btn mt-4"
                href={award.certificate.publicURL}
                rel="noreferrer"
                target="_blank"
              >
                {t('awards.view_certificate')}
              </a>
            )}
          </div>
        </section>
      ))}
    </>
  );
};
