import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { ReactComponent as LogoVertical } from '@/assets/logo-full-vertical.svg';
import { Awards } from '@/components/Awards';
import BgImage from '@/components/BgImage';
import Footer from '@/components/Footer';
import Head from '@/components/Head';
import NavBar from '@/components/NavBar';

export default function AwardsPage(): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <Head title={`${t('awards.awards')} | ${t('kota_olive_oil')}`} />

      <NavBar />
      {/* Hero with it's own bg image */}
      <main className="relative">
        <BgImage />
        <section className="relative py-32 pb-16 xl:pb-24 flex items-center flex-col">
          <div className="w-2/3 md:w-1/3 max-w-sm text-gold-default px-16 mb-36">
            <LogoVertical />
          </div>
          <h1 className="title-text uppercase">{t('awards.awards')}</h1>
          <hr className="text-gold-default w-1/2 my-16" />
        </section>
        <Awards />
      </main>

      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
